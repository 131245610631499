.hScroll {
    overflow-x: scroll;
display: flex;
flex-direction: row;
}
.hScroll::-webkit-scrollbar {
    height: 20px;
}
 
.hScroll::-webkit-scrollbar-track {
    background: black;
    border-top: 9px solid white;
    border-bottom: 9px solid white;
    width: 2vw !important;
    background-clip: content-box;
}
 
.hScroll::-webkit-scrollbar-thumb {
    background: black;
    border-top: 7px solid white;
    border-bottom: 7px solid white;
    width: 2vw !important;
}

