html,
body {
  margin: 0;
  position: relative;
  height: 100%;
  scroll-behavior: smooth;
  width: 100%;
}
body{
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
}
#root {
  height: 100%;
  width: 100%;
}
body p {
  margin: 0;
}
.pointer {
  cursor: pointer;
}
a {
  text-decoration: none;
  color: black
}
.cwhite {
  color: white;
}
.cblack {
  color: black;
}
.cred {
  color: #e73e2d;
}
.trans1 {
  -webkit-transition: 0.5s ease-in;
  transition: 0.5s ease-in;
}
.trans2 {
  -webkit-transition: 2s ease-in;
  transition: 2s ease-in;
}
.mix {
  mix-blend-mode: difference;
}
#outer {
  z-index: 99999;
}
.padding_default {
  padding-left: 250px;
  padding-right: 250px;
}
.swiper-button-next {
  margin: 0 250px;
  z-index: 999999999 !important;
}
.swiper-button-prev {
  margin: 0 250px;
  z-index: 999999 !important;
}
image{
  display: block;
}
@font-face{
    font-family: "SFUIDisplay-Black";
    src: local('SFUIDisplay-Black'),
    url(/static/media/SFUIDisplay-Black.f3a98daa.woff) format('woff'),
    url(/static/media/SFUIDisplay-Black.40e8fc32.eot) format('eot'),
    url(/static/media/SFUIDisplay-Black.0880836d.svg) format('svg');
}
@font-face{
    font-family: "SFUIDisplay-Bold";
    src: local('SFUIDisplay-Bold'),
    url(/static/media/SFUIDisplay-Bold.9670f343.woff) format('woff'),
    url(/static/media/SFUIDisplay-Bold.a238b533.eot) format('eot'),
    url(/static/media/SFUIDisplay-Bold.794456ba.svg) format('svg');
}
@font-face{
    font-family: "SFUIDisplay-Heavy";
    src: local('SFUIDisplay-Heavy'),
    url(/static/media/SFUIDisplay-Heavy.068c565a.woff) format('woff'),
    url(/static/media/SFUIDisplay-Heavy.507054bc.eot) format('eot');
}
@font-face{
    font-family: "SFUIDisplay-Light";
    src: local('SFUIDisplay-Light'),
    url(/static/media/SFUIDisplay-Light.7942efce.woff) format('woff'),
    url(/static/media/SFUIDisplay-Light.b4ea292f.eot) format('eot'),
    url(/static/media/SFUIDisplay-Light.c08e2d11.svg) format('svg');
}
@font-face{
    font-family: "SFUIDisplay-Medium";
    src: local('SFUIDisplay-Medium'),
    url(/static/media/SFUIDisplay-Medium.8814c349.woff) format('woff'),
    url(/static/media/SFUIDisplay-Medium.fa14a42a.eot) format('eot'),
    url(/static/media/SFUIDisplay-Medium.8842d02e.svg) format('svg');
}
@font-face{
    font-family: "SFUIDisplay-Regular";
    src: local('SFUIDisplay-Regular'),
    url(/static/media/SFUIDisplay-Regular.ebd68071.woff) format('woff'),
    url(/static/media/SFUIDisplay-Regular.71d685cf.eot) format('eot'),
    url(/static/media/SFUIDisplay-Regular.09ca6164.svg) format('svg');
}
@font-face{
    font-family: "SFUIDisplay-Semibold";
    src: local('SFUIDisplay-Semibold'),
    url(/static/media/SFUIDisplay-Semibold.51a338fc.woff) format('woff'),
    url(/static/media/SFUIDisplay-Semibold.2deead45.eot) format('eot'),
    url(/static/media/SFUIDisplay-Semibold.5c2e7bd7.svg) format('svg');
}
@font-face{
    font-family: "SFUIDisplay-Thin";
    src: local('SFUIDisplay-Thin'),
    url(/static/media/SFUIDisplay-Thin.770caa5f.woff) format('woff'),
    url(/static/media/SFUIDisplay-Thin.95eac5b2.eot) format('eot'),
    url(/static/media/SFUIDisplay-Thin.1cd06342.svg) format('svg');
}
@font-face{
    font-family: "SFUIDisplay-Ultralight";
    src: local('SFUIDisplay-Ultralight'),
    url(/static/media/SFUIDisplay-Ultralight.206e9291.woff) format('woff'),
    url(/static/media/SFUIDisplay-Ultralight.81b17597.eot) format('eot'),
    url(/static/media/SFUIDisplay-Ultralight.71748160.svg) format('svg');
}
.hScroll {
    overflow-x: scroll;
display: -webkit-flex;
display: flex;
-webkit-flex-direction: row;
        flex-direction: row;
}
.hScroll::-webkit-scrollbar {
    height: 20px;
}
 
.hScroll::-webkit-scrollbar-track {
    background: black;
    border-top: 9px solid white;
    border-bottom: 9px solid white;
    width: 2vw !important;
    background-clip: content-box;
}
 
.hScroll::-webkit-scrollbar-thumb {
    background: black;
    border-top: 7px solid white;
    border-bottom: 7px solid white;
    width: 2vw !important;
}


.swiper-slide{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
