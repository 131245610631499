html,
body {
  margin: 0;
  position: relative;
  height: 100%;
  scroll-behavior: smooth;
  width: 100%;
}
body{
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
}
#root {
  height: 100%;
  width: 100%;
}
body p {
  margin: 0;
}
.pointer {
  cursor: pointer;
}
a {
  text-decoration: none;
  color: black
}
.cwhite {
  color: white;
}
.cblack {
  color: black;
}
.cred {
  color: #e73e2d;
}
.trans1 {
  transition: 0.5s ease-in;
}
.trans2 {
  transition: 2s ease-in;
}
.mix {
  mix-blend-mode: difference;
}
#outer {
  z-index: 99999;
}
.padding_default {
  padding-left: 250px;
  padding-right: 250px;
}
.swiper-button-next {
  margin: 0 250px;
  z-index: 999999999 !important;
}
.swiper-button-prev {
  margin: 0 250px;
  z-index: 999999 !important;
}
image{
  display: block;
}